import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation TokenAuth(
    $email: String!
    $password: String!
    $utmCampaign: String
  ) {
    tokenAuth(email: $email, password: $password, utmCampaign: $utmCampaign) {
      payload
      refreshExpiresIn
      token
      refreshToken
      user {
        id
        isStaff
        isSuperuser
        defaultCompanyRut
        isExternalSupport
      }
    }
  }
`;

export default LOGIN;
