import { gql } from '@apollo/client';
import { InstallmentFields, InstallmentFieldsWithConnection, InstallmentManagerFields, SimpleAmortizationScheduleFragment } from './fragment';
import { MasterEntityBasicField, RiskBlacklistFields } from '../customers/fragment';
import { ContentTypeFields } from '../third_party/fragments';
import { CollectionManagerBasicFields, CollectionActionsFields } from '../collection/fragments';

export const AMORTIZATION_SCHEDULE = gql`
  query amortizationSchedules (
    $first: Int
    $offset: Int
    $status: String
    $status_In: [String]
    $contentType_Model: String
    $orderBy: String
    $globalFilter: String
    $masterEntity_Id: ID
    $masterEntity_Country_Id: ID
  ) {
    amortizationSchedules (
      first: $first
      offset: $offset
      status: $status
      status_In: $status_In
      contentType_Model: $contentType_Model
      orderBy: $orderBy
      globalFilter: $globalFilter
      masterEntity_Id: $masterEntity_Id
      masterEntity_Country_Id: $masterEntity_Country_Id
    ) {
      totalPages
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ... SimpleAmortizationScheduleFragment
          masterEntity {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
          }
          contentType {
            ... ContentTypeFields
          }
          installments {
            ... InstallmentFields
            installmentManager {
              ... InstallmentManagerFields
            }
          }
        }
      }
    }
  }
  ${SimpleAmortizationScheduleFragment}
  ${InstallmentFields}
  ${InstallmentManagerFields}
  ${MasterEntityBasicField}
  ${ContentTypeFields}
  ${RiskBlacklistFields}
`;

export const GET_PAYMENT_PLAN = gql`
  query getPaymentPlan($principalAmount: Float!, $monthlyRate: Float!, $upfrontAmount: Float!, $installmentDates: [Date]!, $upfrontPaymentDate: Date!) {
    getPaymentPlan(principalAmount: $principalAmount, monthlyRate: $monthlyRate, upfrontAmount: $upfrontAmount, installmentDates: $installmentDates, upfrontPaymentDate: $upfrontPaymentDate) {
      paymentDate
      amortizationToPay
      interestAmountToPay
      totalAmountToPay
    }
  }
`;

export const AMORTIZATION_SCHEDULE_NO_INSTALLMENTS = gql`
  query amortizationScheduleNoInstallments(
    $first: Int
    $offset: Int
    $status: String
    $status_In: [String]
    $contentType_Model: String
    $orderBy: String
    $globalFilter: String
    $masterEntity_Id: ID
    $masterEntity_Country_Id: ID
    ) {
    amortizationSchedules(
      first: $first
      offset: $offset
      status: $status
      status_In: $status_In
      contentType_Model: $contentType_Model
      orderBy: $orderBy
      globalFilter: $globalFilter
      masterEntity_Id: $masterEntity_Id
      masterEntity_Country_Id: $masterEntity_Country_Id) {
      totalPages
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ... SimpleAmortizationScheduleFragment
          expirationDate
          masterEntity {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
          }
          contentType {
            ... ContentTypeFields
          }
        }
      }
    }
  }
  ${SimpleAmortizationScheduleFragment}
  ${MasterEntityBasicField}
  ${ContentTypeFields}
  ${RiskBlacklistFields}
`;

export const GET_INSTALLMENTS_BY_IDS = gql`
  query getInstallments(
    $first: Int
    $offset: Int
    $orderBy: String
    $globalFilter: String
    $amortizationScheduleId: ID
    $id_In: [String]) {
    getInstallments(
      first: $first
      offset: $offset
      orderBy: $orderBy
      globalFilter: $globalFilter
      amortizationScheduleId: $amortizationScheduleId
      id_In: $id_In) {
        totalPages
        totalCount
        pageInfo {
          startCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
          ... InstallmentFieldsWithConnection
          installmentManager {
            ... InstallmentManagerFields
            collectionManager {
              ... CollectionManagerBasicFields
            }
          }
        }
      }
    }
  }
  ${InstallmentFieldsWithConnection}
  ${InstallmentManagerFields}
  ${CollectionManagerBasicFields}
`;

export const GET_INSTALLMENTS = gql`
query getInstallments(
  $first: Int
  $offset: Int
  $orderBy: String
  $globalFilter: String
  $amortizationScheduleId: ID
  $id_In: [String]) {
  getInstallments(
    first: $first
    offset: $offset
    orderBy: $orderBy
    globalFilter: $globalFilter
    amortizationScheduleId: $amortizationScheduleId
    id_In: $id_In) {
      totalPages
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
        ... InstallmentFieldsWithConnection
        installmentManager {
          ... InstallmentManagerFields
          collectionManager {
            ... CollectionManagerBasicFields
            actions {
              ... CollectionActionsFields
            }
          }
        }
      }
    }
  }
}
${InstallmentFieldsWithConnection}
${InstallmentManagerFields}
${CollectionManagerBasicFields}
${CollectionActionsFields}
`;

export const AMORTIZATION_SCHEDULE_PENDING_COLLECTION_MANAGERS = gql`
  query amortizationSchedulesPendingCollectionManagers (
    $first: Int
    $offset: Int
    $status: String
    $status_In: [String]
    $contentType_Model: String
    $orderBy: String
    $globalFilter: String
    $masterEntity_Id: ID
    $masterEntity_Country_Id: ID
  ) {
    amortizationSchedules (
      first: $first
      offset: $offset
      status: $status
      status_In: $status_In
      contentType_Model: $contentType_Model
      orderBy: $orderBy
      globalFilter: $globalFilter
      masterEntity_Id: $masterEntity_Id
      masterEntity_Country_Id: $masterEntity_Country_Id
    ) {
      totalPages
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ... SimpleAmortizationScheduleFragment
          masterEntity {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
          }
          contentType {
            ... ContentTypeFields
          }
          pendingCollectionManagers {
            id
            actions {
              ... CollectionActionsFields
            }
          }
        }
      }
    }
  }
  ${SimpleAmortizationScheduleFragment}
  ${MasterEntityBasicField}
  ${ContentTypeFields}
  ${RiskBlacklistFields}
  ${CollectionActionsFields}
`;
